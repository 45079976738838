/* eslint-disable import/extensions */

// Services Img Imports
import Web from '../assets/images/Services/drafting.jpg';
import Mobile from '../assets/images/Services/consultant.png';
import UIUX from '../assets/images/Services/estimate.jpg';
import construction from '../assets/images/Services/construction.jpg';
import engineering from '../assets/images/Services/engineering.jpg';
import signage from '../assets/images/Services/signage.png';

// Portfolio Img Imports
import Recruiting from '../assets/images/Portfolio/2.jpg';
import Stream from '../assets/images/Portfolio/1.jpg';
import Freelance from '../assets/images/Portfolio/2b.jpg';
import Aura from '../assets/images/Portfolio/3.jpg';
import Surtido from '../assets/images/Portfolio/3b.jpg';
import ManagementApp from '../assets/images/Portfolio/8.jpg';
import pagibig from '../assets/images/Portfolio/pagibig.png';
import Airconserivce from '../assets/images/Portfolio/aircon.jpeg';
import lightingservice from '../assets/images/Portfolio/lighting services.jpeg';
import signage2 from '../assets/images/Portfolio/signage2.jpeg';
import signage3 from '../assets/images/Portfolio/signage3.jpeg';
import signage4 from '../assets/images/Portfolio/signage4.jpeg';
import signage5 from '../assets/images/Portfolio/signage5.jpeg';
import signage6 from '../assets/images/Portfolio/signage6.jpeg';
import signage7 from '../assets/images/Portfolio/signage7.jpeg';
import signage8 from '../assets/images/Portfolio/signage8.jpeg';
import Airconservice1 from '../assets/images/Portfolio/aircon2.jpeg';
import Airconservice2 from '../assets/images/Portfolio/aircon3.jpeg';
import Airconservice3 from '../assets/images/Portfolio/aircon4.jpeg';
import Airconservice4 from '../assets/images/Portfolio/aircon5.jpeg';
import Airconservice5 from '../assets/images/Portfolio/aircon6.jpeg';
import Airconservice6 from '../assets/images/Portfolio/aircon7.jpeg';
import Airconservice7 from '../assets/images/Portfolio/aircon8.jpeg';
import Airconservice8 from '../assets/images/Portfolio/aircon9.jpeg';
import Airconservice9 from '../assets/images/Portfolio/aircon10.jpeg';
import Airconservice10 from '../assets/images/Portfolio/aircon11.jpeg';
import Airconservice11 from '../assets/images/Portfolio/aircon12.jpeg';
import lightingservice2 from '../assets/images/Portfolio/lighting2.jpeg';
import lightingservice3 from '../assets/images/Portfolio/lighting3.jpeg';
import lightingservice4 from '../assets/images/Portfolio/lighting4.jpeg';
import lightingservice5 from '../assets/images/Portfolio/lighting5.jpeg';
import lightingservice6 from '../assets/images/Portfolio/lighting6.jpeg';
import lightingservice7 from '../assets/images/Portfolio/lighting7.jpeg';

// Advantages
import Communicative from '../assets/images/Advantages/Communicative.png';
import Collaborative from '../assets/images/Advantages/Collaborative.png';
import Management from '../assets/images/Advantages/Management.png';
import Favorite from '../assets/images/Advantages/Favorite.png';

// Testimonials
import Sasha from '../assets/images/Testimonials/Sasha.jpg';
import Reiner from '../assets/images/Testimonials/Reiner.jpg';
import Kruger from '../assets/images/Testimonials/Kruger.jpg';

// TeamMembers
import CEO from '../assets/images/TeamMembers/CEO.jpg';
import HRD from '../assets/images/TeamMembers/engr.jpg';
import Finance from '../assets/images/TeamMembers/Sales.jpg';
import ProjectManager from '../assets/images/TeamMembers/consultant.jpg';
import Frontend1 from '../assets/images/TeamMembers/Mechanical.jpg';
import Frontend2 from '../assets/images/TeamMembers/Accounting.jpg';
import Backend1 from '../assets/images/TeamMembers/HR.jpg';
import Backend2 from '../assets/images/TeamMembers/Marketing.jpg';

export const Services = [
  {
    title: 'Design and Drafting / Drawing',
    imageUrl: Web,
    animation: 'left',
  },
  {
    title: 'Design Consultancy',
    imageUrl: Mobile,
    animation: 'up',
  },
  {
    title: 'Material Estimation and Surveying',
    imageUrl: UIUX,
    animation: 'right',
  },
  {
    title: 'Construction (Design and Build)',
    imageUrl: construction,
    animation: 'left',
  },
  {
    title: 'Value-Engineering',
    imageUrl: engineering,
    animation: 'up',
  },
  {
    title: 'Signage Design and Installation',
    imageUrl: signage,
    animation: 'right',
  },
];

export const Portfolios = [
  {
    id: 'asd1293uasdads1',
    title: '"Escasa Residence" 3-storey residence',
    imageUrl: Recruiting,
    type: 'Modern Architectural Design',
    details: 'Project Budget: 2.4 Million pesos',
    responsibility: [
      'Construction',
      'Architecture',
    ],
   
  },
  {
    id: 'asd1293uhjkhkjh2',
    title: '"Ikon" Two-storey High-end super car bar',
    imageUrl: Stream,
    type: 'Modern design to showcase supercars',
    details: ' Project Budget: 85 Million pesos',

    responsibility: [
      'Construction',
      'Architecture',
    ],
   
  },
  {
    id: 'asd1293uvbvcbbd3',
    title: '"Rhino Head Office" Rhino Head Development Inc. Head Quarter',
    imageUrl: Freelance,
    type: 'Classical-contemporary and modern design architecture',
    details: 'Project Budget: 25.5 Million pesos',
    responsibility: [
      'Construction',
      'Architecture',
    ],
    
  },
  {
    id: 'asd1293ufgdfgs4',
    title: '"The Pod" Three-storey pod capsule hotel',
    imageUrl: Aura,
    type: 'A modern designed capsule hotel inspired by American Modern Design',
    details: 'Project Budget: 15.5 Million Pesos',
    responsibility: [
      'Construction',
      'Architecture',
    ],
   
  },
  {
    id: 'asd1293ulskmnb5',
    title: 'SM Savemore Renovation',
    imageUrl: Surtido,
    type: 'Renovation using modern architechtural finishes and materials.',
    details: 'Project Budget: 4.1 Million Pesos',
    responsibility: [
      'Construction',
      
    ],
  
  },
  {
    id: 'asd1293ulkmnbj6',
    title: 'King Fahd University - Petroleum and Mineral Business Park',
    imageUrl: ManagementApp,
    type: 'Modernized Residential, office towers and infrastructure scheme',
    details: 'Project Budget: 603 Million SAR',
    responsibility: [
      'Architecture',
      'Construction',
    ],
   
  },
  {
    id: 'asd1293ulkmnbj5',
    title: 'Pag-ibig Signage',
    imageUrl: pagibig,
    type: 'Signage',
    details: 'Pag-ibig signage and lightning',
    responsibility: [
      'Signages',
      'Lightning Feature',
    ],
   
  },
  {
    id: 'asd1293ulkmnbs5',
    title: 'Harvester Baptist Church',
    imageUrl: signage2,
    type: 'Signage',
    details: 'Harvester Baptist Church',
    responsibility: [
      'Signages',
      'Lightning Feature',
    ],
  },
  {
    id: 'asd1293ulkmdsdxcbs5',
    title: 'Kings and Queen Signage',
    imageUrl: signage3,
    type: 'Signage',
    details: 'Signage',
    responsibility: [
      'Signages',
      'Lightning Feature',
    ],
  },
  {
    id: 'dsfbvcb',
    title: 'Whiz Kids Montessori Car Decals',
    imageUrl: signage4,
    type: 'Signage',
    details: 'Car Improvement',
    responsibility: [
      'Signages',
    ],
  },
  {
    id: 'sdvcxxcv',
    title: 'Tricycle Decals',
    imageUrl: signage5,
    type: 'Signage',
    details: 'Car Improvement',
    responsibility: [
      'Signages',
    ],
  },
  {
    id: 'wqexcc',
    title: 'DHL Decals',
    imageUrl: signage6,
    type: 'Signage',
    details: 'Car Improvement',
    responsibility: [
      'Signages',
    ],
  },
  {
    id: 'cxvxcbv',
    title: 'Reign-Nan Business Card',
    imageUrl: signage7,
    type: 'Signage',
    details: 'Printing Service',
    responsibility: [
      'Signages',
    ],
  },
  {
    id: 'xczxcxzv',
    title: 'Evelo Water Station Signage',
    imageUrl: signage8,
    type: 'Signage',
    details: 'Printing Service',
    responsibility: [
      'Signages',
    ],
  },
  {
    id: 'asd1293ulkmnbj4',
    title: 'Airconditioning Services',
    imageUrl: [
      Airconserivce, 
    ], 
    OtherPics: [
      Airconservice2,
      Airconservice3,
      Airconservice4,
      Airconservice5,
      Airconservice6,
      Airconservice8,
      Airconservice9,
      Airconservice10,
      Airconservice7,
      Airconservice11,
    ],

    type: 'Supply, Install & Repairs of any Airconditioning Units',
    details: 'Supply, Install & Repairs of any Airconditioning Units',
    responsibility: [
      'Aircon Services',
    ],
  },

  {
    id: 'asd1293ulkmnbj3',
    title: 'Lighting Fixtures',
    imageUrl: lightingservice,
    OtherPics: [
      Airconservice1,
      lightingservice2,
      lightingservice3,
      lightingservice4,   
      lightingservice5,
      lightingservice6,
      lightingservice7,                 
    ],

    type: 'Supply & Installation of Lighting Fixtures',
    details: 'Supply & Installation of Lighting Fixtures',
    responsibility: [
      'Lighting Fixtures',
    ],
  },
];

export const Advantages = [
  [{
    title: 'History',
    description: 'ARKICOOL RESIDENTIAL BUILDING CONSTRUCTION, led by Arch. Randy Barlizo Serra, provides comprehensive design-consultancy, construction, engineering, air-conditioning, and signage solutions, backed by years of expertise and successful projects.',
    imageUrl: Communicative,
  },
  {
    title: 'Vision',
    description: 'To procure projects at unique Conceptualization Design, applied Value-Engineering Construction, Competitive Pricing in Air-Conditioning and Signages Dealership, Sales, Installation and Maintenance, providing safe working conditions and deliver quality work within areasonable time frame with applied value- engineering.',
    imageUrl: Management,
  }],
  [{
    title: 'Mission',
    description: 'To be a respectable Design- Consultancy, Construction,Engineering, Signages and Air-Conditioning Dealer, Installer and Maintenance Provider delivering beyond expectation, always.',
    imageUrl: Collaborative,
  },
  {
    title: 'Objectives',
    description: "Our goal is to provide clients with a confident and assured experience on every project. By 2030, we aim to be a leading design-consultation, construction, signage, and air-conditioning service provider in the Philippines and internationally, while growing our profit by Php 100 million.",
    imageUrl: Favorite,
  }],
];
export const Testimonials = [
  {
    id: 1,
    name: 'Arch. Randy Serra',
    company: 'President and CEO',
    testimoni: 'Reduce Risk, improve operational performance and enhance commercial outcomes',
    imageUrl: Sasha,
  },
  {
    id: 2,
    name: 'Engr. Leo Peñaflor',
    company: 'Project Manager / Site Operation Head',
    testimoni: 'We deliver engineering excellence from design through to completion, for small works to complex infastructure projects',
    imageUrl: Kruger,
  },
  {
    id: 3,
    name: 'Ms. Analyn Cordovilla-Serra',
    company: 'Sales & Marketing Manager',
    testimoni: 'Our dedicated support enables you to focus on high-quality design activities.',
    imageUrl: Reiner,
  },
];

export const TeamMembers = [
  {
    name: 'Arch. Randy Serra',
    position: 'President/CEO',
    imageUrl: CEO,
  },
  {
    name: 'Engr. Leo Peñaflor',
    position: 'Project Manager / Site Operation Head',
    imageUrl: HRD,
  },
  {
    name: 'Ms. Analyn Cordovilla-Serra',
    position: 'Sales & Marketing Manager',
    imageUrl: Finance,
  },
  {
    name: 'Mr. Alex Cordovilla',
    position: 'Signages Design Manager',
    imageUrl: ProjectManager,
  },
  {
    name: 'Mr. Abelardo Cordovilla',
    position: 'Mechanical / Air-Con. Consultant',
    imageUrl: Frontend1,
  },
  {
    name: 'Ms. Aleck Yzabel Cordovilla',
    position: 'Accounting / Finance Specialist',
    imageUrl: Frontend2,
  },
  {
    name: 'Ms. Elemily Narvasa Visco',
    position: 'Accounting & Human Resource Manager',
    imageUrl: Backend1,
  },
  {
    name: 'Mr. Mhelroe Tibudan',
    position: 'Marketing Specialist',
    imageUrl: Backend2,
  },
  
];
